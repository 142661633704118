
import React from 'react'
import { Link } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { StaticImage } from 'gatsby-plugin-image'

import Sprite from 'utils/Sprite'
import NavLink from 'utils/NavLink'

import { userAtom } from 'utils/atoms'


export default function Layout({ children, mainClassName = '' }) {

	const { isAuth } = useRecoilValue(userAtom)
	
	return <>

		<nav id='Navbar'>

			<Link to='/' id='Logo'>
				<span id='LogoTitle'>
					<strong>Nameless</strong>
					<small>BETA</small>
				</span>
				<StaticImage 
					src='../images/icanhaz.webp' 
					placeholder='blurred'
					// width={62}
					height={64}
					className='LogoImage'
					quality={80}
					alt="On the Internet nobody knows you're a cat"
				/>
			</Link>

			{ isAuth
				?	<>
						<NavLink to='/chat/'>Chat</NavLink> <br />
						<NavLink to='/account/'>Account</NavLink> 
					</>
				:	<>
						{/* <Link to='/signin/'>Sign in</Link> */}
					</>
			}

		</nav>

		{ children }

		<footer id='Footer'>
			
			<Link to='/privacy/'>Privacy</Link>
			<Link to='/terms/'>Terms</Link>
			<p className='email'>contact@nameless.im</p>

			<p className='twitter'>Created by <a href='https://twitter.com/UnemploymentCEO'>@UnemploymentCEO</a></p>

		</footer>

		<Sprite />

	</>
}
