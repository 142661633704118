
import React from 'react'
import { RecoilRoot } from 'recoil'

import Auth from 'user/Auth'
import Layout from 'layout/Layout'

import 'styles/global.sass'


export const wrapRootElement = ({element}) => 
	<RecoilRoot>
		<Auth />
		<Layout>
			{ element }
		</Layout>
	</RecoilRoot>