
import { initializeApp } from 'firebase/app'

let instance

async function connectEmulator() {
	console.log('connect Functions emulator')
	const { getFunctions, connectFunctionsEmulator } = await import('firebase/functions')
	connectFunctionsEmulator(getFunctions(instance), 'localhost', 5001)
}

if (typeof window !== 'undefined' && !instance) {
	instance = initializeApp(JSON.parse(process.env.FIREBASE_CONFIG))	
	if (process.env.NODE_ENV === 'development') connectEmulator()
}

export default instance