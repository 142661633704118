
import React from 'react'
import { Link } from 'gatsby'


export default function NavLink({ ...props }) {
	return <Link 
		getProps={({ isPartiallyCurrent }) => isPartiallyCurrent 
			? { className: `active ${props.className}` } 
			: {}
		}
		{...props}
	/>
}
