
import React from 'react'
import { useSetRecoilState } from 'recoil'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

import firebase from 'utils/firebase'

import { userAtom } from 'utils/atoms'


export default function Auth() {

	const setUser = useSetRecoilState(userAtom)
	
	React.useEffect(() => {

		if (!!localStorage.getItem('signedinbefore')) {
			setUser({ 
				uid: null,
				isAuth: false,
				isSigningIn: true,
			})
		}

		const auth = getAuth(firebase)

		return onAuthStateChanged(auth, user => {
			if (user?.uid) {
				const facebookAuth = user.providerData.find(d => d.providerId === 'facebook.com')
				setUser({
					isAuth: true,
					uid: user.uid,
					isSigningIn: false,
					isFacebookAuth: !!facebookAuth
				})
				localStorage.setItem('signedinbefore', '1')
			} else {
				setUser({
					uid: null,
					isAuth: false,
					isSigningIn: false,
					isFacebookAuth: false,
				})
				localStorage.removeItem('signedinbefore')
			}
		})

	}, [setUser])
	
	return null
}