
import { atom, atomFamily, selector, selectorFamily, DefaultValue } from 'recoil'

export const userAtom = atom({
	key: 'user',
	default: {
		isAuth: false,
		// isAnonymous: null,
		uid: null,
		isSigningIn: null,
		isFacebookAuth: null,
	}
})

export const roomsAtom = atom({
	key: 'rooms',
	default: null,
})

export const roomAtom = selectorFamily({
	key: 'room',
	get: roomId => ({ get }) => {
		const rooms = get(roomsAtom)
		return rooms.find(r => r.id === roomId)
	}
})

export const newMessagesAtom = selector({
	key: 'newMessages',
	get: ({ get }) => {
		const rooms = get(roomsAtom)
		if (!Array.isArray(rooms)) return null
		let total = 0
		for (let room of rooms) {
			const lastRead = get(lastReadAtom(room.id))
			if (room.updated?.seconds && lastRead < room.updated.seconds) total++
		}
		return total
	}
})

export const messagesAtom = atomFamily({
	key: 'messages',
	default: null,
})

export const inviteCodesAtom = atomFamily({
	key: 'inviteCodes',
	default: null,
})

export const lastReadAtom = atomFamily({
	key: 'lastRead',
	default: null,
	effects_UNSTABLE: roomId => [ localStorageEffect(roomId) ]
})


function localStorageEffect(roomId) {
	return function({ setSelf, onSet }) {
		if (typeof localStorage === 'undefined') return
		const itemKey = `${roomId}-lastRead`
		const savedValue = localStorage.getItem(itemKey)
		if (savedValue !== null) setSelf(Number(savedValue))
		onSet(newValue => {
			if (newValue instanceof DefaultValue) {
				localStorage.removeItem(itemKey)
			} else {
				localStorage.setItem(itemKey, newValue)
			}
		})
	}
}